import * as React from "react";

import styled from "../../theme/styled";
import { colourHelper } from "../../theme/theme-helper";
import Icon from "../icon";
import Link from "../link";
import { VacancyBadgeWrapper } from "../vacancy-badge";

interface IProps {
  menuType: "mobile" | "light" | "dark";
  onToggle?: () => void;
}

const MenuWrapper = styled.nav<IProps>`
  display: block;
  padding-top: 1.6rem;
  width: 26rem;
  height: 100%;
  background: ${({ theme }) => colourHelper({
    theme,
    variant: "borderColor"
  })};
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.4);

  a {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 3.4rem;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 400;
    color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "black"
    })};

    &.active,
    &:hover {
      color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
    }
  }

  @media (min-width: 950px) {
    display: inline-block;
    padding-top: 0;
    width: auto;
    height: auto;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    a {
      display: inline-block;
      padding: 1rem 1rem;
      color: ${({ theme, menuType }) =>
    colourHelper({
      theme,
      variant: menuType === "light" ? "black" : "white"
    })};
      opacity: 0.5;

      &:last-child {
        display: none;
      }

      &.active,
      &:hover {
        color: ${({ theme, menuType }) =>
    colourHelper({
      theme,
      variant: menuType === "light" ? "black" : "white"
    })};
        opacity: 1;
      }
    }
  }

  @media (min-width: 1200px) {
    a {
      padding: 1rem 1.8rem;
    }
  }
`;

const MobileCloseWrapper = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  right: 2rem;
  height: 60px;
  width: 60px;

  &:hover {
    cursor: pointer;
  }

  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 950px) {
    display: none;
  }
`;

const Menu = (props: IProps): JSX.Element => (
  <MenuWrapper
    menuType={props.menuType}
    className="navigation"
    role="navigation"
    aria-label="Main navigation (header)"
  >
    <MobileCloseWrapper
      onClick={() => {
        if (props.onToggle) {
          props.onToggle();
        }
      }}
    >
      <Icon
        className="icon"
        color="#ed6f2d"
        icon="close"
        size={1.5}
      />
    </MobileCloseWrapper>

    <Link
      type="none"
      activeClassName="active"
      href="/projects"
      getProps={({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: "active" } : {};
      }}
    >
      Projects
    </Link>

    <Link
      type="none"
      activeClassName="active"
      href="/about-us"
      getProps={({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: "active" } : {};
      }}
    >
      About us
    </Link>

    <Link
      type="none"
      activeClassName="active"
      href="/impact"
      getProps={({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: "active" } : {};
      }}
    >
      Impact
    </Link>

    <Link
      type="none"
      activeClassName="active"
      href="/investment"
      getProps={({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: "active" } : {};
      }}
    >
      Investment
    </Link>

    <Link
      type="none"
      activeClassName="active"
      href="/insights"
      getProps={({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: "active" } : {};
      }}
    >
      Insights
    </Link>

    <Link
      type="none"
      activeClassName="active"
      href="/careers"
      getProps={({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: "active" } : {};
      }}
    >
      <span style={{ alignSelf: "flex-start" }}>
        <VacancyBadgeWrapper>
          Careers
        </VacancyBadgeWrapper>
      </span>
    </Link>

    <Link
      type="none"
      activeClassName="active"
      href="/contact"
      getProps={({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: "active" } : {};
      }}
    >
      Contact us
    </Link>
  </MenuWrapper>
);

export default Menu;
