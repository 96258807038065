import {
  GravitywellGradients,
  IGradients
} from "./gradient-list";

const randomGradientName = (): keyof IGradients => {
  const keys = Object.keys(GravitywellGradients) as Array<keyof IGradients>;
  const index = Math.floor(Math.random() * keys.length);

  return keys[ index ];
};

export {
  GravitywellGradients,
  randomGradientName
};
