import styled from "../../theme/styled";
import styledWithProps from "../../helpers/styled-with-props";
import generator, { IGradientOption } from "./generator";
import { IGradients } from "./gradient-list";

interface IProps {
  gradient: keyof IGradients;
  angle: number;
  type?: "radial";
  options?: IGradientOption;
}

const Gradient = styledWithProps<IProps, HTMLDivElement>(styled.div)`
  ${props => generator(props)};
`;

export default Gradient;
