export interface IGradients {
  orangeYellow: string[];
  orangePink: string[];
  blueGreen: string[];
  pinkPurple: string[];
  pinkOrange: string[];
  purplePink: string[];
  purpleOrange: string[];
  adelieBlueGreen: string[];
  lifetimeBlue: string[];
  lightPinkPurple: string[];
  purpleToOrange: string[];
  darkBlueOrange: string[];
  royalBlueNavy: string[];
}

export const GravitywellGradients: IGradients = {
  // blueGreen: [ "#a2d106", "#18c491" ],
  blueGreen: [ "#70af94", "#70af94" ],
  // orangeYellow: [ "#faa61a", "#f04e23" ],
  orangeYellow: [ "#ed6f2d", "#ed6f2d" ],
  // orangePink: [
  //   "#f02370",
  //   "#f04e23",
  //   "#faa61a"
  // ],
  orangePink: [
    "#ed6f2d",
    "#ed6f2d",
    "#ed6f2d"
  ],
  // pinkPurple: [ "#701262", "#d65851" ],
  pinkPurple: [ "#937dff", "#937dff" ],
  // pinkOrange: [ "#e9624e", "#711162" ],
  pinkOrange: [ "#937dff", "#937dff" ],
  // purplePink: [ "#d65851", "#701262" ],
  purplePink: [ "#937dff", "#937dff" ],
  // purpleOrange: [
  //   "#181a32",
  //   "#a66466",
  //   "#181a32"
  // ],
  purpleOrange: [
    "#937dff",
    "#937dff",
    "#937dff"
  ],
  // purpleToOrange: [ "#be4f4d", "#181a32" ],
  purpleToOrange: [ "#937dff", "#937dff" ],
  // adelieBlueGreen: [ "#96bd17", "#46ab98" ],
  adelieBlueGreen: [ "#70af94", "#70af94" ],
  // lifetimeBlue: [ "#35c6ea", "#21366d" ],
  lifetimeBlue: [ "#937dff", "#937dff" ],
  // lightPinkPurple: [ "#ddcedc", "#eed9d9" ],
  lightPinkPurple: [ "#dbdbd2", "#dbdbd2" ],
  // darkBlueOrange: [
  //   "#0f1334",
  //   "#713548",
  //   "#0f1334"
  // ],
  darkBlueOrange: [
    "#937dff",
    "#937dff",
    "#937dff"
  ],
  // royalBlueNavy: [ "#101c2e", "#366ab4" ]
  royalBlueNavy: [ "#937dff", "#937dff" ]
};
