import * as React from "react";

import { Gradient } from "../gradients";
import Text from "../text";
import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper
} from "../../theme/theme-helper";

const CopyrightWrapper = styled.div`
  position: relative;
  padding-top: 4.6rem;
  padding-bottom: 4.6rem;
  background-color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};

  .copyright {
    display: block;
    padding: 0 20px;
    position: relative;
    z-index: 2;
    text-align: center;
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "large"
    })};
  }

  @media (min-width: 768px) {
    .copyright {
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
    }
  }
`;

const CopyRightFooterWrapper = () => {
  const date = new Date();

  return (
    <CopyrightWrapper>
      <Text
        className="copyright"
        fontSize="small_3"
        fontWeight="light"
        fontColor="third"
      >
        <>
          Bristol's Venture Studio
          <br />

          <br />
          &copy; Gravitywell

          {" "}

          {date.getFullYear()}

          {" "}
          | Registered in England
          09173389 | VAT Registered 194 0383 02
        </>
      </Text>

    </CopyrightWrapper>
  );
};

export default CopyRightFooterWrapper;
