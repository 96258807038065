import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule } from "styled-components";

import { ITheme } from "./theme-definition";

const {
  default: styled,
  css,
  injectGlobal,
  keyframes,
  ThemeProvider,
  withTheme
} = styledComponents as ThemedStyledComponentsModule<ITheme>;

export {
  css,
  injectGlobal,
  keyframes,
  ThemeProvider,
  withTheme
};

export default styled;
