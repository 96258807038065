import { css } from "styled-components";

import { IGradients } from "./gradient-list";
import {
  GravitywellGradients,
  randomGradientName
} from "./gradients";

export interface IGradientOption {
  position?: string;
  shape?: "ellipse" | "circle"; // 'circle' or 'ellipse'
  colorStops?: string[]; // Can be percentage or pixel values
  extent?:
    | "farthest-corner"
    | "closest-side"
    | "farthest-side"
    | "closest-corner"
    | "";
}

export type IGradientParams = string[];

function generateRadialGradientCss(
  options: IGradientOption,
  gradientColors: IGradientParams
) {
  const {
    shape, position, extent, colorStops
  } = options;

  return css`
    background-image: -webkit-radial-gradient(
      ${shape} ${extent} at ${position},
      ${generateGradientCss(gradientColors, colorStops)}
    );
    background-image: -moz-radial-gradient(
      ${shape} ${extent} at ${position},
      ${generateGradientCss(gradientColors, colorStops)}
    );
    background-image: -o-radial-gradient(
      ${shape} ${extent} at ${position},
      ${generateGradientCss(gradientColors, colorStops)}
    );
    background-image: radial-gradient(
      ${shape} ${extent} at ${position},
      ${generateGradientCss(gradientColors, colorStops)}
    );
  `;
}

function configRadialGradientOptions(
  options: IGradientOption
): IGradientOption {
  const {
    position, shape, colorStops, extent
  } = options;

  const radialConfig: IGradientOption = {
    colorStops: [ "", "" ],
    extent: "",
    position: "center",
    shape: "circle"
  };

  if (position) {
    radialConfig.position = position;
  }

  if (shape && (shape === "circle" || shape === "ellipse")) {
    radialConfig.shape = shape;
  }

  if (Array.isArray(colorStops)) {
    radialConfig.colorStops = colorStops;
  }

  if (
    extent === "closest-side" ||
    extent === "closest-corner" ||
    extent === "farthest-side" ||
    extent === "farthest-corner"
  ) {
    radialConfig.extent = extent;
  }

  return radialConfig;
}

function generateGradientCss(
  colors: string[],
  colorStops?: string[]
): string {
  let gradientCSS = "";

  colors.map((c: string, index: number) => {
    gradientCSS += `${c} ${colorStops ? colorStops[ index ] || "" : ""}`;
    gradientCSS += index < colors.length - 1 ? "," : "";

    return gradientCSS;
  });

  return gradientCSS;
}

function generator(props: {
  gradient: keyof IGradients;
  angle?: number;
  options?: IGradientOption;
  type?: "radial";
}) {
  let gradient: keyof IGradients = "orangeYellow";
  let angle = -90;

  if (props.gradient === undefined) {
    gradient = randomGradientName();
  } else {
    gradient = props.gradient;
  }

  if (props.angle !== undefined) {
    angle = props.angle;
  }
  const { options, type } = props;

  if (type === "radial" && options) {
    const config = configRadialGradientOptions(options);

    return generateRadialGradientCss(config, GravitywellGradients[ gradient ]);
  }

  return css`
    background-color: ${GravitywellGradients[ gradient ][ 0 ]};
    background-image: -webkit-linear-gradient(
      ${angle}deg,
      ${generateGradientCss(
    GravitywellGradients[ gradient ],
    options ? options.colorStops : []
  )}
    );
    background-image: -moz-linear-gradient(
      ${angle}deg,
      ${generateGradientCss(
    GravitywellGradients[ gradient ],
    options ? options.colorStops : []
  )}
    );
    background-image: -o-linear-gradient(
      ${angle}deg,
      ${generateGradientCss(
    GravitywellGradients[ gradient ],
    options ? options.colorStops : []
  )}
    );
    background-image: linear-gradient(
      ${angle}deg,
      ${generateGradientCss(
    GravitywellGradients[ gradient ],
    options ? options.colorStops : []
  )}
    );
  `;
}

export default generator;
