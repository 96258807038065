import { Column } from "hedron";
import React from "react";

import styled from "../../theme/styled";

const ColumnWrapper = styled(Column)`
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 118.6rem;
`;

const MediumColumnWidthWrapper = (props: any) => {
  return <ColumnWrapper {...props} />;
};

export default MediumColumnWidthWrapper;
