import { Link } from "gatsby";
import _omit from "lodash/omit";
import React from "react";

import Icon from "../icon";
import styled from "../../theme/styled";
import LogoIcon from "./logo-icon";

const LogoWrapper = styled(Link)`
  transition: 0.4s ease-in-out;
  display: block;
`;

const Logo = ({
  type,
  noLink
}: {
  type: "light" | "dark";
  noLink?: boolean;
}) => {
  const Test = Object.assign({}, LogoIcon);

  // remove the gradient on the white version
  if (type === "dark") {
    Test.paths = [];

    LogoIcon.paths.forEach((p: any) =>
      // @ts-ignore
      Test.paths.push(_omit(p, "linearGradient"))
    );
  }

  return (
    <>
      {noLink ? (
        <Icon
          className="logo no-link"
          color={type === "dark" ? "#f9f4f0" : "#000000"}
          custom={Test}
        />
      ) : (
        <LogoWrapper
          to="/"
          className="logo"
        >
          <Icon
            color={type === "dark" ? "#f9f4f0" : "#000000"}
            custom={Test}
          />
        </LogoWrapper>
      )}
    </>
  );
};

export default Logo;
