import { ThemedStyledFunction } from "styled-components";

import { ITheme } from "../theme/theme-definition";

type StyledFunction<T> = ThemedStyledFunction<T, ITheme>;

const styledWithProps = <T, U extends HTMLElement = HTMLElement>(
  styledFunction: StyledFunction<React.HTMLProps<U>>
): StyledFunction<T & React.HTMLProps<U>> =>
  (styledFunction as any) as StyledFunction<T & React.HTMLProps<U>>;

export default styledWithProps;
