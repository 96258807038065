import * as React from "react";

import styled from "../../theme/styled";
import {
  IFontColor,
  IFontLineHeight,
  IFontSize,
  IFontSpacing,
  IFontWeight
} from "../../theme/theme-definition";
import {
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper,
  textColourHelper
} from "../../theme/theme-helper";

export interface ITextProps extends Pick<React.HTMLAttributes<HTMLDivElement>, "className" | "style"> {
  fontSize?: keyof IFontSize;
  fontWeight?: keyof IFontWeight;
  fontColor?: keyof IFontColor;
  lineHeight?: keyof IFontLineHeight;
  letterSpacing?: keyof IFontSpacing;
}

const TextStyled = styled<ITextProps, "span">("span")`
  ${({
    theme, fontColor, fontWeight, letterSpacing, fontSize, lineHeight
  }) => `
  color: ${textColourHelper({
    theme,
    variant: fontColor || "primary"
  })};
  display: inline-block;
  font-weight: ${fontWeightHelper({
    theme,
    variant: fontWeight || "regular"
  })};
  font-family: inherit;
  letter-spacing: ${fontSpaceHelper({
    theme,
    variant: letterSpacing || "default"
  })};
  font-size: ${fontSizeHelper({
    theme,
    variant: fontSize || "body_2"
  })};
  line-height: ${fontLineHeightHelper({
    theme,
    variant: lineHeight || "default"
  })};
`};
`;

const Text: React.FC<ITextProps> = props => (
  <TextStyled
    className={props.className}
    fontSize={props.fontSize}
    fontWeight={props.fontWeight}
    fontColor={props.fontColor}
    lineHeight={props.lineHeight}
    letterSpacing={props.letterSpacing}
    style={props.style}
  >
    {props.children}
  </TextStyled>
);

export default Text;
