import Cookies from "js-cookie";
import * as React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { HalfColumnWidthWrapper } from "../confines-wrappers";
import { Gradient } from "../gradients";
import Icon from "../icon/icon";

const NewsletterSignupPopUpStyled = styled.aside`
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 99999;
  bottom: -11.8rem;
  right: 0;
  width: 100%;
  height: 11.8rem;
  background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "lightestGrey"
    })};

  &.show {
    bottom: 0;
  }

  .newsletter-signup-wrapper {
    position: relative;

    .content-wrapper {
      transition: 0.2s ease-in-out;

      .heading {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_1"
    })};
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "regular"
    })};
      }

      .newsletter-signup-form {
        padding-top: 1.4rem;

        .email-wrapper {
          position: relative;

          input[type="email"],
          .press-enter {
            font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "small_2"
    })};
            line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
          }

          input[type="email"] {
            padding: 1.6rem 9rem 1.6rem 1.8rem;
            width: 100%;
            background: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};
            border: none;
            outline: none;

            &::-webkit-input-placeholder {
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
            }

            &::-moz-placeholder {
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
            }

            &:-ms-input-placeholder {
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
            }

            &:-moz-placeholder {
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
            }

            &::placeholder {
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
            }
          }

          .press-enter {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 2;
            top: 50%;
            right: 1.8rem;
            transform: translateY(-50%);
            padding-top: 0;
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
            opacity: 0.6;

            .icon {
              fill: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "midGrey"
    })};
            }

            span {
              display: block;
              padding-left: 0.8rem;
              width: 4.2rem;
            }
          }
        }

        button {
          display: none;
        }
      }
    }

    .icon-container {
      display: none;

      .icon {
        fill: ${({ theme }) => colourHelper({
    theme,
    variant: "periwinkle"
  })};
        opacity: 0.8;
      }
    }

    .close-container {
      position: absolute;
      z-index: 2;
      top: 2rem;
      right: 2rem;
      cursor: pointer;

      .icon {
        fill: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
      }
    }
  }

  @media (min-width: 375px) {
    .newsletter-signup-wrapper {
      .content-wrapper {
        padding-left: 10rem;
      }

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        width: 8rem;
        height: 8rem;
      }
    }
  }

  @media (min-width: 600px) {
    transition: 0.4s ease-in-out;
    width: 42rem;
    right: 2rem;
    box-shadow: 0 0 1.4rem 0 rgba(0, 0, 0, 0.2);

    &.show {
      bottom: 2rem;
    }

    .newsletter-signup-wrapper {
      .content-wrapper {
        padding-left: 12rem;
      }

      .icon-container {
        left: 0;
        width: 11.8rem;
        height: 11.8rem;
      }
    }
  }
`;

/**
 * Newsletter sign-up pop-up
 */
const NewsletterSignupPopUp = () => {
  const timeToShow = 2000;
  const [ shouldShow, setShouldShow ] = React.useState<boolean>(false);
  const cookie = Cookies.get("gw-newsletter-popup");
  const [ makeVisible, setMakeVisible ] = React.useState<boolean>(false);

  const onClose = () => {
    setMakeVisible(false);
    // Make sure that the pop-up doesn't reappear until after 365 days
    Cookies.set("gw-newsletter-popup", "no-appear", { expires: 365 });
  };

  // On mount set the shouldShow value
  React.useEffect(() => {
    setShouldShow(!cookie);
  }, [ cookie ]);

  // On shouldShow change
  React.useEffect(() => {
    // If shouldShow is true, wait for it to render before adding the 'show' class
    if (shouldShow) {
      setTimeout(() => {
        setMakeVisible(true);
      }, timeToShow);
    }
  }, [ shouldShow ]);

  // On makeVisible change
  React.useEffect(() => {
    // If shouldShow is true and makeVisible is false, hide then remove the DOM
    if (shouldShow && !makeVisible) {
      setTimeout(() => {
        setShouldShow(false);
      }, 200);
    }
  }, [ makeVisible, shouldShow ]);

  if (!shouldShow) {
    return null;
  }

  return (
    <NewsletterSignupPopUpStyled
      className={`newsletter-signup-container${makeVisible ? " show" : ""}`}
    >
      <HalfColumnWidthWrapper className="newsletter-signup-wrapper">
        <div className="content-wrapper">
          <h2 className="heading">
            Sign-up to our newsletter
          </h2>

          <form
            className="newsletter-signup-form"
            action="https://gravitywell.us3.list-manage.com/subscribe/post"
            method="POST"
          >
            <div className="field-row">
              <div className="email-wrapper">
                <input
                  type="email"
                  name="MERGE0"
                  placeholder="Enter your email"
                />

                <p className="press-enter">
                  <Icon
                    className="icon"
                    icon="enterArrow"
                    size={1.4}
                  />

                  {" "}

                  <span>
                    Press enter
                  </span>
                </p>
              </div>
            </div>

            <div className="field-row">
              <input
                type="hidden"
                name="u"
                value="c18fa34a44acaa24637bbd5d1"
              />

              <input
                type="hidden"
                name="id"
                value="6b2d541b64"
              />

              <button
                type="submit"
                name="submit"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>

        <Gradient
          className="icon-container"
          gradient="purpleOrange"
          angle={75}
          options={{ colorStops: [ "80%", "100%" ] }}
        >
          <Icon
            className="icon"
            icon="satellite"
            size={5.4}
          />
        </Gradient>

        <span
          className="close-container"
          onClick={() => onClose()}
        >
          <Icon
            className="icon"
            icon="close"
            size={1.6}
          />
        </span>
      </HalfColumnWidthWrapper>
    </NewsletterSignupPopUpStyled>
  );
};

export default NewsletterSignupPopUp;
