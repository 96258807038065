import { CSSProperties } from "react";
import {
  AnchorProps as ReactRouterLinkProps,
  LinkGetProps
} from "@reach/router";
import { Link as InternalLink } from "gatsby";
import * as React from "react";
import styled, { css } from "styled-components";

import forceTrailingSlash from "../../templates/utils/forceTrailingSlash";
import { IThemeColour } from "../../theme/theme-definition";
import {
  colourHelper,
  fontLineHeightHelper
} from "../../theme/theme-helper";

type LinkTypes = "none" | "inline" | "styled" | "styled-box";

interface IProps {
  id?: string;
  className?: string;
  ariaLabel?: string;
  ariaCurrent?: boolean;
  href: string;
  target?: string;
  rel?: string;
  type?: LinkTypes;
  color?: keyof IThemeColour;
  underlinecolor?: keyof IThemeColour;
  activeClassName?: string;
  activeStyle?: CSSProperties;
  getProps?: (props: LinkGetProps) => Record<string, unknown>;
  innerRef?: () => void;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  uppercase?: boolean;
}

interface IInternalLink extends ReactRouterLinkProps {
  className?: string;
  ariaLabel?: string;
  ariaCurrent?: boolean;
  activeClassName?: string;
  activeStyle?: CSSProperties;
  getProps?: (props: LinkGetProps) => Record<string, unknown>;
  innerRef?: () => void;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  to: string;
  type?: LinkTypes;
  color?: keyof IThemeColour;
  underlinecolor?: keyof IThemeColour;
  uppercase?: boolean;
}

interface IExternalLink extends ReactRouterLinkProps {
  ariaLabel?: string;
  ariaCurrent?: boolean;
  href: string;
  type?: LinkTypes;
  color?: keyof IThemeColour;
  underlinecolor?: keyof IThemeColour;
  uppercase?: boolean;
}

interface ILinkCSS {
  type?: LinkTypes;
  color?: keyof IThemeColour;
  underlinecolor?: keyof IThemeColour;
  uppercase?: boolean;
}

const uppercaseStyling = css`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 300;
  font-size: 0.8em;
`;

const inlineLinkCSS = css<ILinkCSS>`
  display: inline;

  &.uppercase {
    text-transform: uppercase;
  }
`;

const styledLinkCSS = css<ILinkCSS>`
  display: inline-block;

  border-bottom-color: ${({
    theme, color, underlinecolor
  }) =>
    colourHelper({
      theme,
      variant: underlinecolor || color || "brandColor"
    })}40;

  ${({ uppercase }) => uppercase ? uppercaseStyling : ""}
`;

const styledBoxLinkCSS = css<ILinkCSS>`
  display: inline-block;
  border: 2px solid;
  padding: 0.66em 1.5em;

  border-color: ${({
    theme, color, underlinecolor
  }) =>
    colourHelper({
      theme,
      variant: underlinecolor || color || "brandColor"
    })} !important;

  ${({ uppercase }) => uppercase ? uppercaseStyling : ""}

  transition: all 0.35s ease-in-out;
  
  &:hover {
    background: ${({
    theme, color, underlinecolor
  }) => colourHelper({
    theme,
    variant: underlinecolor || color || "brandColor"
  })};
    color: black !important;
  }
`;

export const linkCSS = css<ILinkCSS>`
  position: relative;
  padding-bottom: 0.2rem;
  color: ${({ theme, color }) =>
    colourHelper({
      theme,
      variant: color || "brandColor"
    })};
  text-decoration: none;
  line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
  border-bottom: 0.2rem solid transparent;
  ${({ type }) => loadStyles(type)}

  &:hover {
    color: ${({ theme, color }) =>
    colourHelper({
      theme,
      variant: color || "brandColor"
    })};
    border-bottom-color: ${({
    theme, color, underlinecolor
  }) =>
    colourHelper({
      theme,
      variant: underlinecolor || color || "brandColor"
    })};
  }

  ${({ uppercase }) => uppercase ? uppercaseStyling : ""}
`;

const CustomInternalLink = styled<IInternalLink, any>(InternalLink)`
  /*
// @ts-ignore */
  ${({ type }) => {
    if (type === "none") {
      return;
    }

    return linkCSS;
  }}
`;

const CustomExternalLink = styled<IExternalLink, "a">("a")`
  /*
// @ts-ignore */
  ${({ type }) => {
    if (type === "none") {
      return;
    }

    return linkCSS;
  }}
`;

const loadStyles = (type?: LinkTypes) => {
  let css: any = null;

  switch (type) {
    default:
    case "inline":
      css = inlineLinkCSS;
      break;
    case "styled":
      css = styledLinkCSS;
      break;
    case "styled-box":
      css = styledBoxLinkCSS;
      break;
    case "none":
      css = null;
  }

  return css;
};

const InternalLinkWrapper: React.FC<IInternalLink> = ({
  className,
  ariaLabel,
  ariaCurrent,
  activeClassName,
  activeStyle,
  getProps,
  innerRef,
  onClick,
  to,
  type,
  color,
  rel,
  underlinecolor,
  uppercase,
  children
}) => {
  // Force internal URLs to have a trailing slash to better page loading
  // times within Amplify if they don't have an anchor in the URL
  if (!to.match(/[#]/)) {
    to = forceTrailingSlash(to);
  }

  return (
    <CustomInternalLink
      to={to}
      type={type}
      color={color}
      underlinecolor={underlinecolor}
      className={className}
      aria-label={ariaLabel}
      aria-current={ariaCurrent}
      activeClassName={activeClassName}
      activeStyle={activeStyle}
      getProps={getProps}
      rel={rel}
      innerRef={innerRef}
      onClick={onClick}
      uppercase={uppercase}
    >
      {children}
    </CustomInternalLink>
  );
};

const ExternalLinkWrapper = ({
  id,
  className,
  ariaLabel,
  ariaCurrent,
  type,
  color,
  underlinecolor,
  href,
  target,
  rel,
  uppercase,
  children
}: IExternalLink): JSX.Element => (
  <CustomExternalLink
    id={id}
    className={className}
    type={type}
    color={color}
    underlinecolor={underlinecolor}
    href={href}
    target={target}
    rel={rel}
    aria-label={ariaLabel}
    aria-current={ariaCurrent}
    uppercase={uppercase}
  >
    {children}
  </CustomExternalLink>
);

const Link: React.FC<IProps> = ({
  id,
  className,
  ariaLabel,
  ariaCurrent,
  href,
  target,
  rel,
  type,
  color,
  underlinecolor,
  activeClassName,
  activeStyle,
  getProps,
  innerRef,
  onClick,
  uppercase,
  children
}) => {
  const internalExternalRegex = /^(?:\/|#)(?:.*)/g;
  // Removes Drupal uri meta
  const cleansedHref = href.replace(/internal:|external:/i, "");

  // If the href looks like a absolute path
  if (internalExternalRegex.test(cleansedHref)) {
    return (
      <InternalLinkWrapper
        type={type}
        color={color}
        underlinecolor={underlinecolor}
        to={cleansedHref}
        className={className}
        uppercase={uppercase}
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        getProps={getProps}
        innerRef={innerRef}
        onClick={onClick}
        rel={rel}
        ariaLabel={ariaLabel}
        ariaCurrent={ariaCurrent}
      >
        {children}
      </InternalLinkWrapper>
    );
  }

  return (
    <ExternalLinkWrapper
      id={id}
      className={className}
      uppercase={uppercase}
      type={type}
      color={color}
      underlinecolor={underlinecolor}
      href={cleansedHref}
      target={target || "_blank"}
      rel={target === "_blank" ? "noreferrer noopener nofollow" : rel || "nofollow"}
      ariaLabel={ariaLabel}
      ariaCurrent={ariaCurrent}
    >
      {children}
    </ExternalLinkWrapper>
  );
};

export default Link;
