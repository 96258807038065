import { Column } from "hedron";
import React from "react";

import styled from "../../theme/styled";

const ColumnWrapper = styled(Column)`
  --w: 136rem;

  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  max-width: var(--w);
  position: relative;

  @media only screen and (min-width: 1920px) {
    &[data-sidetext]::before {
      content: attr(data-sidetext);
      position: absolute;
      /* left: calc((50% - (var(--w) / 2)) - 200px); */
      left: calc(-1 * (10vw));
      top: 50%;
      white-space: nowrap;
      transform: translate(-50%, -50%) rotate(-90deg);
      transform-origin: 50% 0%;
      color: inherit;
      font-size: 14px;
    }
  }
`;

const ColumnWidthWrapper = (props: any) => {
  const { sideText, ...restProps } = props;

  return (
    <ColumnWrapper
      {...sideText ? { "data-sideText": sideText } : {}}
      {...restProps}
    />
  );
};

export default ColumnWidthWrapper;
