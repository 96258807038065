import { Column } from "hedron";
import React from "react";

import styled from "../../theme/styled";

const ColumnWrapper = styled(Column)`
  padding-top: 0;
  padding-bottom: 0;
`;

const FullWidthWrapper = (props: any) => {
  return <ColumnWrapper {...props} />;
};

export default FullWidthWrapper;
