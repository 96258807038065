export default {
  color: "e6ee72",
  name: "Logo",
  paths: [
    { d: "M477.452 1.224L411.752 148.124C410.152 145.624 408.752 143.024 407.652 140.324C405.652 135.724 404.052 131.124 402.652 126.324L398.852 114.424C398.052 111.724 397.152 109.024 395.952 106.524C394.952 104.324 393.752 102.224 392.252 100.224C390.852 98.324 389.252 96.624 387.352 95.124C385.352 93.524 383.252 92.224 380.952 91.024C384.752 89.524 388.552 87.724 392.052 85.524C395.352 83.524 398.252 81.024 400.752 78.124C403.252 75.224 405.152 71.924 406.452 68.324C407.852 64.324 408.552 60.024 408.452 55.724V50.724C408.452 42.524 406.852 35.324 403.752 29.224C400.552 23.024 395.952 17.724 390.252 13.724C384.452 9.524 377.452 6.42404 369.152 4.32404C360.952 2.22404 351.752 1.224 341.652 1.224H238.052V151.124H291.652V101.724H321.452C326.752 102.024 331.852 103.524 336.452 106.224C339.952 108.324 342.352 111.724 343.652 116.424L348.052 130.924C348.552 133.124 349.252 135.224 349.952 137.324C350.752 139.324 351.452 141.224 352.252 143.024C352.952 144.624 353.852 146.224 354.752 147.724C355.452 148.924 356.252 150.024 357.152 151.124H465.552L475.752 125.324H547.852L558.052 151.124H613.252L546.152 1.224H477.452ZM354.052 51.224C354.052 53.824 353.552 55.924 352.652 57.724C351.752 59.424 350.352 60.924 348.752 62.024C346.952 63.124 345.052 63.924 342.952 64.324C340.652 64.824 338.352 65.024 336.052 65.024H291.852V36.624H338.652C343.152 36.624 346.852 37.724 349.752 39.824C352.652 41.924 354.052 45.324 354.052 49.924V51.224ZM489.752 89.724L510.152 38.224H513.752L534.052 89.724H489.752Z" },
    { d: "M636.052 151.124L570.652 1.02405H627.552C627.552 1.02405 668.452 97.224 669.052 97.224C669.652 97.324 710.752 1.02405 710.752 1.02405H767.652L702.252 151.124H636.052Z" },
    { d: "M778.952 151.124H839.252V1.02405H778.952V151.124Z" },
    { d: "M1018.85 44.524H965.252V151.124H904.952V44.524H851.252V1.02405H1018.85V44.524Z" },
    { d: "M1088.55 1.02405L1124.75 57.624L1161.75 1.02405H1225.15L1154.25 110.224V151.424L1093.95 151.224V109.824L1025.15 1.02405H1088.55Z" },
    { d: "M1286.45 1.02405L1313.35 83.724L1340.45 1.02405H1392.85L1419.55 82.724L1446.85 1.02405H1502.95L1453.55 151.124H1391.95L1366.45 66.624L1340.95 151.124H1279.25L1229.45 1.02405H1286.45Z" },
    { d: "M1564.85 94.524H1648.75V57.424H1564.85V38.624H1659.55V1.32404H1511.75V151.224H1660.15V114.124H1564.85V94.524Z" },
    { d: "M1882.35 114.024V1.224L1828.65 1.32404V151.124H1967.25V114.024H1882.35Z" },
    { d: "M1730.45 114.024V1.224L1676.75 1.32404V151.124H1815.35V114.024H1730.45Z" },
    { d: "M218.752 1.02405C207.052 1.02405 195.252 1.02405 183.552 1.02405H182.852L182.352 1.52405C175.952 7.82405 169.452 14.024 163.052 20.324C151.452 31.624 139.952 42.924 128.352 54.124C119.052 63.224 109.752 72.424 100.452 81.624C96.452 85.524 92.452 89.5241 88.452 93.4241C85.752 96.1241 82.552 97.9241 78.652 98.4241C66.152 99.9241 54.752 91.024 53.152 78.524C52.952 76.524 52.952 74.524 53.252 72.524C55.052 61.224 64.752 53.824 75.052 53.424C81.152 53.224 87.252 53.324 93.352 53.324C102.152 53.324 105.952 53.424 114.652 53.424L168.852 0.824036H70.952C70.352 0.824036 69.852 0.824036 69.252 0.824036C65.452 1.12404 61.652 1.724 58.052 2.724C49.252 4.924 41.152 8.724 33.652 13.724C23.352 20.524 15.152 29.224 9.25197 40.124C5.55197 47.024 2.85197 54.524 1.35197 62.324C0.451967 66.824 -0.148034 71.324 0.0519664 75.924C0.0519664 76.624 0.0519664 77.324 0.0519664 78.124C0.0519664 79.924 0.0519666 81.724 0.351967 83.524C0.851967 86.124 1.05197 88.824 1.65197 91.424C3.35197 100.024 6.75197 108.124 11.452 115.524C17.852 125.724 26.052 134.124 36.452 140.324C44.052 144.824 52.252 148.024 60.952 149.724C64.152 150.424 67.452 150.924 70.752 151.224C75.452 151.424 80.052 151.324 84.752 150.724C90.652 150.024 96.552 148.524 102.052 146.324C111.152 142.724 119.152 137.324 126.152 130.424C139.452 117.224 152.852 104.124 166.152 90.924C166.252 90.824 166.452 90.624 166.652 90.424V151.024H219.552V1.02405H218.752Z" }
  ],
  viewBox: [
    "0",
    "0",
    "1968",
    "152"
  ]
};

// export default {
//   color: "E9530E",
//   name: "Logo",
//   paths: [
//     {
//       d:
//         "M18.55,9.09c-0.89-4.04-4.53-6.97-8.66-6.97c-0.64,0-1.29,0.07-1.92,0.21C5.66,2.84,3.68,4.22,2.4,6.22 c-1.28,2-1.7,4.37-1.19,6.69c0.89,4.04,4.53,6.97,8.66,6.97c0.64,0,1.29-0.07,1.92-0.21c2.31-0.51,4.29-1.89,5.57-3.89 C18.63,13.78,19.05,11.4,18.55,9.09z M6.06,15.78c0.59-0.13,1.19-0.2,1.79-0.2c2.28,0,4.48,0.97,6.06,2.67 c-0.7,0.39-1.46,0.68-2.25,0.85c-0.59,0.13-1.19,0.2-1.79,0.2c-2.28,0-4.49-0.97-6.06-2.66C4.51,16.24,5.27,15.95,6.06,15.78z M5.68,13.93c1.99-0.44,4.02-0.66,6.03-0.66c1.97,0,3.97,0.22,5.93,0.65c-0.62,1.64-1.76,3.07-3.22,4.02 C12.72,16.08,10.33,15,7.85,15c-0.64,0-1.28,0.07-1.92,0.21c-0.88,0.19-1.73,0.52-2.51,0.98c-0.34-0.42-0.64-0.88-0.9-1.37 C3.56,14.46,4.62,14.16,5.68,13.93z M17.82,13.37c-2.02-0.45-4.08-0.68-6.12-0.68c-2.06,0-4.13,0.23-6.16,0.67 c-1.1,0.24-2.2,0.55-3.28,0.93c-0.18-0.4-0.32-0.83-0.43-1.26l16.19-3.56C18.27,10.77,18.2,12.11,17.82,13.37z M4.98,10.64 c4.06-0.89,7.8-2.62,11.09-5.13c0.87,0.98,1.5,2.15,1.82,3.4L1.72,12.47c-0.08-0.43-0.12-0.87-0.13-1.3 C2.74,11.06,3.88,10.88,4.98,10.64z M9.68,4.59C8.49,6.46,6.64,7.75,4.48,8.23c-0.59,0.13-1.19,0.2-1.79,0.2 C2.47,8.42,2.25,8.41,2,8.39c0.22-0.65,0.52-1.28,0.89-1.86C4.08,4.66,5.93,3.37,8.09,2.9c0.59-0.13,1.19-0.2,1.79-0.2 c0.22,0,0.45,0.01,0.68,0.03C10.35,3.39,10.05,4.02,9.68,4.59z M4.6,8.79C6.92,8.28,8.9,6.9,10.17,4.9 c0.41-0.65,0.75-1.36,0.98-2.1c1.69,0.27,3.29,1.08,4.52,2.28C12.46,7.52,8.82,9.2,4.86,10.07C3.8,10.3,2.7,10.48,1.59,10.59 c0.03-0.55,0.11-1.1,0.25-1.63C2.14,8.99,2.42,9,2.69,9C3.33,9,3.97,8.93,4.6,8.79z",
//       linearGradient: {
//         id: "logoGradient",
//         x1: "3.6098",
//         y1: "17.2658",
//         x2: "16.1437",
//         y2: "4.7319",
//         stops: [
//           {
//             offset: "0%",
//             color: "#E64011"
//           },
//           {
//             offset: "100%",
//             color: "#F6A500"
//           }
//         ]
//       }
//     },
//     {
//       d:
//         "M27.66,7.96c1.36,0,2.09,0.68,2.09,0.68l-0.31,0.43c0,0-0.68-0.58-1.75-0.58c-1.42,0-2.38,1.09-2.38,2.49 c0,1.44,0.99,2.51,2.33,2.51c1.14,0,1.85-0.86,1.85-0.86v-0.99h-1v-0.52h1.55v2.81h-0.53v-0.44c0-0.13,0.01-0.25,0.01-0.25h-0.02 c0,0-0.68,0.79-1.91,0.79c-1.6,0-2.87-1.29-2.87-3.04C24.7,9.3,25.97,7.96,27.66,7.96z"
//     },
//     {
//       d:
//         "M33.43,8.06h1.74c0.6,0,0.87,0.06,1.1,0.16c0.55,0.24,0.89,0.8,0.89,1.51c0,0.79-0.45,1.4-1.11,1.59v0.02 c0,0,0.06,0.08,0.15,0.23l1.28,2.36h-0.66l-1.33-2.47H34v2.47h-0.58V8.06z M35.4,10.95c0.71,0,1.16-0.46,1.16-1.19 c0-0.48-0.19-0.84-0.53-1.03c-0.18-0.09-0.38-0.14-0.84-0.14H34v2.36H35.4z"
//     },
//     {
//       d:
//         "M43.57,12.06h-2.36l-0.67,1.87h-0.61l2.15-5.87h0.62l2.15,5.87h-0.61L43.57,12.06z M42.39,8.67c0,0-0.13,0.48-0.23,0.76 l-0.79,2.13h2.02l-0.77-2.13c-0.1-0.28-0.23-0.76-0.23-0.76H42.39z"
//     },
//     {
//       d:
//         "M46.82,8.06h0.62l1.63,4.46c0.11,0.3,0.23,0.76,0.23,0.76h0.02c0,0,0.12-0.46,0.23-0.76l1.63-4.46h0.62l-2.18,5.87H49 L46.82,8.06z"
//     },
//     { d: "M54.73,8.06h0.58v5.87h-0.58V8.06z" },
//     { d: "M60.34,8.58h-2.11V8.06h4.79v0.52h-2.1v5.35h-0.58V8.58z" },
//     {
//       d:
//         "M67.3,11.41l-1.98-3.35h0.66l1.24,2.13c0.18,0.3,0.36,0.7,0.36,0.7h0.02c0,0,0.18-0.39,0.36-0.7l1.24-2.13h0.65 l-1.97,3.35v2.52H67.3V11.41z"
//     },
//     {
//       d:
//         "M75.85,8.92c0,0-0.09,0.5-0.19,0.88l-1.12,4.13h-0.69l-1.52-5.87h0.6l1.14,4.56c0.08,0.3,0.13,0.63,0.13,0.63h0.02 c0.01,0,0.05-0.33,0.13-0.63l1.24-4.56h0.55l1.24,4.56c0.08,0.3,0.13,0.63,0.13,0.63h0.02c0,0,0.06-0.33,0.13-0.63l1.18-4.56h0.6 l-1.56,5.87h-0.69L76.06,9.8c-0.1-0.38-0.19-0.88-0.19-0.88H75.85z"
//     },
//     {
//       d:
//         "M82.6,8.06h3.31v0.52h-2.73v2.14h2.23v0.52h-2.23v2.18h2.88v0.52H82.6V8.06z"
//     },
//     { d: "M89.37,8.06h0.58v5.35h2.72v0.52h-3.3V8.06z" },
//     { d: "M95.7,8.06h0.58v5.35H99v0.52h-3.3V8.06z" }
//   ],
//   viewBox: [
//     "0",
//     "0",
//     "100",
//     "22"
//   ]
// };
