import {
  useStaticQuery,
  graphql
} from "gatsby";
import { number } from "prop-types";
import React from "react";

import styled from "src/theme/styled";

import Text from "../text";

interface VacancyCountQuery {
  allNodeCareersVacancy: {
    totalCount: number;
  };
}

interface VacancyBadgeWrapperProps {
  offsetTop?: string;
  offsetRight?: string;
}

export const VacancyBadgeWrapper: React.FC<VacancyBadgeWrapperProps> = ({
  offsetTop = "0.5rem",
  offsetRight = "1.8rem",
  children
}) => {
  const { allNodeCareersVacancy } = useStaticQuery<VacancyCountQuery>(graphql`
    query VacancyCount {
      allNodeCareersVacancy(filter: {status: {eq: true}}) {
        totalCount
      }
    }  
  `);

  return (
    <Container>
      {children}

      <VacancyBadge
        offsetTop={offsetTop}
        offsetRight={offsetRight}
      >
        <Text
          fontSize="small_1"
          fontWeight="light"
          lineHeight="small"
          fontColor="third"
        >
          {allNodeCareersVacancy.totalCount}
        </Text>
      </VacancyBadge>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const VacancyBadge = styled.div<Required<VacancyBadgeWrapperProps>>`
  position: absolute;
  top: -${({ offsetTop }) => offsetTop};
  right: -${({ offsetRight }) => offsetRight};
  height: 1.5rem;
  width: 1.5rem;
  background-color: ${({ theme }) => theme.color.brandColor};
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;