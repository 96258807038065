/**
 * Adds a trailing slash at the end of the given path
 */
const forceTrailingSlash = (path: string) => {
  // If the path already has a trailing slash, simple return
  if (path.charAt(path.length - 1) === "/") {
    return path;
  }

  return `${path}/`;
};

export default forceTrailingSlash;
