import {
  IFixed,
  IFixedObject,
  IFluid,
  IFluidObject,
  ILocalFile
} from "src/types/local-files";

/**
 * Returns a src string for the given image object / data
 *
 * @params data - A local file object from Gatsby
 * @return A src string
 */
export const DynamicImageSrcString = (
  data?: ILocalFile<IFluid & IFixed>
): string => {
  // If data is undefined, return an empty string
  if (!data) {
    return "";
  }

  const localFile = data.localFile;
  const childImageSharp = localFile.childImageSharp;

  // Check if the childImageSharp has fixed or fluid properties
  if (childImageSharp && (childImageSharp.fluid || childImageSharp.fixed)) {
    // If fluid exists, return the src
    if (childImageSharp.fluid) {
      return childImageSharp.fluid.src;
    }

    // If fixed exists, return the src
    if (childImageSharp.fixed) {
      return childImageSharp.fixed.src;
    }
  }

  return localFile.publicURL;
};

/**
 * Returns a Gatsby fluid  object for the given image object / data
 *
 * @params data - A local file object from Gatsby
 * @return Gatsby fluid object
 */
export const DynamicImageFluidObj = (
  data: ILocalFile<IFluid> | undefined | null
): IFluidObject | undefined => {
  // If data does not exist
  if (!data) {
    return undefined;
  }

  // Check if the childImageSharp has fixed or fluid properties
  if (data.localFile.childImageSharp && data.localFile.childImageSharp.fluid) {
    return data.localFile.childImageSharp.fluid;
  }

  return undefined;
};

/**
 * Returns a Gatsby fluid  object for the given image object / data
 *
 * @params data - A local file object from Gatsby
 * @return Gatsby fluid object
 */
export const DynamicImageFixedObj = (
  data: ILocalFile<IFixed> | undefined | null
): IFixedObject | undefined => {
  // If data does not exist
  if (!data) {
    return undefined;
  }

  // Check if the childImageSharp has fixed or fluid properties
  if (data.localFile.childImageSharp && data.localFile.childImageSharp.fixed) {
    return data.localFile.childImageSharp.fixed;
  }

  return undefined;
};
