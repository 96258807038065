import { ITheme } from "../theme/theme-definition";

/** 2023 brand refresh updated colors, as seen here:
https://www.figma.com/file/NkmSP36pH8LSIpkK6dLnL9/Assets?node-id=0%3A1&mode=dev */
export const brand = {
  white: "#f9f4f0",
  black: "#000000",
  bottleGreen: "#70af94",
  tangerine: "#ed6f2d",
  soliel: "#e6ee72",
  ultraviolet: "#937dff",
  warmGrey1: "#dbdbd2"
};

export const GravitywellTheme: ITheme = {
  type: "Desktop",
  version: "2.0",
  owner: "Local Theme",
  description: "Local Used gravitywell landing pages",
  color: {
    // updated brand colors
    white: brand.white,
    black: brand.black,
    bottleGreen: brand.bottleGreen,
    tangerine: brand.tangerine,
    soliel: brand.soliel,
    ultraviolet: brand.ultraviolet,
    warmGrey1: brand.warmGrey1,
    // --------------------
    borderColor: "#E1E0E1",
    borderColorInverse: "#CCCCCC",
    brandColor: brand.tangerine,
    brandColorLight: "#ebaf99",
    brandOneInverse: "#f0226a",
    brandOnePrimary: "#faa51b",
    brandThreeInverse: "#a2d106",
    brandThreePrimary: "#18c491",
    brandTwoInverse: "#d65851",
    brandTwoPrimary: "#701262",
    darkGrey: "#404040",
    midGrey: "#94888a",
    lightGrey: "#aba8af",
    lightestGrey: "#f1eff2",
    altLightGrey: "#f7f6f6",
    silverChalice: "#acacac",
    errorColor: "red",
    periwinkle: "#c3caff",
    lavenderRose: "#f897e3",
    carnationPink: "#ff9bbb",
    fonts: {
      primary: "#1f1e1e",
      secondary: "#ed6f2d",
      third: "#f9f4f0",
      grey: "#858585",
      lightGrey: "#acacac"
    },
    successColor: "green",
    warningColor: "yellow"
  },
  font: {
    decoration: {
      underlineNormal: "0.2rem",
      underlineLight: "0.1rem"
    },
    family: {
      // eslint-disable-next-line quotes
      bold: "\"neue-haas-grotesk-display\", Arial, Helvetica, sans-serif",
      // eslint-disable-next-line quotes
      heavy: "\"neue-haas-grotesk-display\", Arial, Helvetica, sans-serif",
      // eslint-disable-next-line quotes
      light: "\"neue-haas-grotesk-text\", Arial, Helvetica, sans-serif",
      // eslint-disable-next-line quotes
      regular: "\"neue-haas-grotesk-text\", Arial, Helvetica, sans-serif"
    },
    size: {
      display_9: "9rem",
      display_8: "8.2rem",
      display_7: "7.4rem",
      display_6: "6.8rem",
      display_5: "6rem",
      display_4: "5.4rem",
      display_3: "4.8rem",
      display_2: "4.2rem",
      display_1: "3.6rem",
      title: "3.0rem",
      subhead_3: "2.8rem",
      subhead_2: "2.4rem",
      subhead_1: "2.1rem",
      body_2: "1.8rem",
      body_1: "1.6rem",
      small_3: "1.4rem",
      small_2: "1.2rem",
      small_1: "1.1rem"
    },
    lineHeight: {
      default: "1.4em",
      normalized: "1em",
      small: "1.2em",
      large: "1.6em"
    },
    spacing: {
      default: "normal",
      large: "normal",
      medium: "0.2em",
      small: "normal"
    },
    weight: {
      heavy: 900,
      bold: 700,
      light: 300,
      regular: 400
    }
  },
  radius: {
    radius: "0px",
    bottomLeft: "1.25em 1.25em 1.25em 0.0em",
    bottomRight: "1.25em 1.25em 0.0em 1.25em",
    topLeft: "0.0em 1.25em 1.25em 1.25em",
    topRight: "1.25em 0.0em 1.25em 1.25em"
  },
  sizes: {
    spacing: {
      l: "3rem",
      m: "2rem",
      ml: "2.5rem",
      ms: "1.5rem",
      s: "1rem",
      xl: "4rem",
      xs: "0.5rem",
      xxl: "6rem",
      xxs: "0.25rem"
    },
    thickness: {
      l: "3rem",
      m: "2rem",
      ml: "2.5rem",
      ms: "1.5rem",
      s: "1rem",
      xl: "4rem",
      xs: "0.5rem",
      xxl: "6rem",
      xxs: "0.25rem"
    }
  }
};
