import React from "react";
import Helmet from "react-helmet";
import { ParallaxProvider } from "react-scroll-parallax";
import {
  injectGlobal,
  ThemeProvider
} from "styled-components";

import GwLogoImg from "../assets/images/gw-logo-512-transparent.png";
import { GravitywellTheme } from "../config/theme";
import NewsletterSignupPopUp from "./newsletter-signup-pop-up";

interface IProps {
  className?: string;
  children: React.ReactNode;
}

// @import url('https://use.typekit.net/ylz3vhm.css');

injectGlobal`
  @import url('https://use.typekit.net/rxx3ktn.css');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    scroll-behavior: smooth;
  }

  html {
    position: relative;
    font-size: 62.5%; /* font size 10px */
  }

  body {
    font-family: "neue-haas-grotesk-text", Arial, Helvetica, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    min-height: 100vh;
    background-color: #2f2f2f;
    background-image: url(${GwLogoImg});
    background-size: 12rem auto;
    background-position: 50%;
    background-repeat: no-repeat;
    overflow-x: hidden;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  progress {
    vertical-align: baseline;
  }

  [hidden],
  template {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    transition: 0.2s ease-in-out;
    padding-top: 1.4em;
    font-weight: 900;

    &:first-child {
      padding-top: 0;
    }
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 2.1rem;
  }

  h5 {
    font-size: 1.8rem;
  }

  h6 {
    font-size: 1.6rem;
  }

  a {
    transition: 0.15s ease-in-out;
    outline: 0;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:active {
      outline-width: 0;
    }

    img {
      border: 0;
      outline: 0;
    }

    svg {
      transition: 0.15s ease-in-out;
    }
  }

  p {
    transition: 0.2s ease-in-out;
    padding-top: 1.4em;
    font-size: 1.6rem;
    line-height: 1.4em;
    font-weight: 300;

    &:first-child {
      padding-top: 0;
    }

    strong {
      font-weight: 900;
    }

    a {
      display: inline;
    }
  }

  ul,
  ol,
  dl {
    transition: 0.2s ease-in-out;
    padding-top: 1.6em;
    padding-left: 3.8rem;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.4em;

    li {
      transition: 0.2s ease-in-out;
      padding-top: 0.4rem;

      &:first-child {
        padding-top: 0;
      }

      ul {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  img {
    display: block;

    &.full {
      width: 100%;
      height: auto;
    }
  }

  table {
    margin: 2.8rem 0;
    width: 100%;
    border: 0;
    border-spacing: 0;

    th,
    td {
      padding: 0.4rem 1.4rem;
    }

    th {
      font-size: 1.2rem;
      text-align: left;
    }
  }

  legend {
    display: none;
  }

  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='url'],
  input[type='date'],
  input[type='time'],
  input[type='week'],
  input[type='month'],
  input[type='datetime'],
  input[type='datetime-local'],
  textarea,
  button,
  .btn {
    -webkit-appearance: none;
  }

  textarea {
    overflow: auto;
  }

  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      height: auto;
    }
  }

  input[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -0.2rem;

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }

  select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
    font-size: 1.6rem;

    &:focus {
      outline: 0;
      outline-color: transparent;
    }
  }

  textarea {
    padding: 1rem 1.2rem;
    min-height: 20rem;
  }

  button,
  input[type='submit'] {
    &:focus {
      outline: 0;
      outline-color: transparent;
    }

    &:hover,
    &:hover {
      transition: 0.15s ease-in-out;
      cursor: pointer;
    }
  }

  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  .hide {
    display: none;
  }

  .off-screen {
    position: absolute;
    left: -99999rem;
    text-indent: -99999rem;
  }

  .ltr {
    direction: ltr;
  }

  .rtl {
    direction: ltr;
  }

  .video-container {
    position: relative;
    padding-top: 56.25%; /* 16:9 ratio */

    .video,
    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .content-container {
    transition: 0.2s ease-in-out;
    padding-top: 14% !important;
    padding-bottom: 14% !important;
  }

  @media (min-width: 480px) {
    .content-container {
      padding-top: 7.4rem !important;
      padding-bottom: 7.4rem !important;
    }
  }

  @media (min-width: 768px) {
    .content-container {
      padding-top: 8.8rem !important;
      padding-bottom: 8.8rem !important;
    }
  }

  @media (min-width: 1280px) {
    .content-container {
      padding-top: 9.4rem !important;
      padding-bottom: 9.4rem !important;
    }
  }

  @media (min-width: 1440px) {
    .content-container {
      padding-top: 12.8rem !important;
      padding-bottom: 12.8rem !important;
    }
  }

  @media (min-width: 1920px) {
    .content-container {
      padding-top: 16rem !important;
      padding-bottom: 16rem !important;
    }
  }
`;

const Layout: React.FunctionComponent<IProps> = props => (
  <ThemeProvider
    theme={{
      ...GravitywellTheme,
      mode: "normal"
    }}
  >
    <ParallaxProvider>
      <Helmet />

      <aside className="hide">
        <p>
          Accessibility shortcut links
        </p>

        <ul
          role="navigation"
          aria-label="Accessibility shortcut links"
        >
          <li>
            <a href="#main-navigation">
              Skip to Main Navigation (header)
            </a>
          </li>

          <li>
            <a href="#main-content">
              Skip to Main Content
            </a>
          </li>

          <li>
            <a href="#footer">
              Skip to Supplementary Navigation (footer)
            </a>
          </li>
        </ul>
      </aside>

      <div
        className={props?.className}
        style={{
          margin: "0 auto",
          paddingTop: 0,
          background: "#f9f4f0"
        }}
      >
        {props?.children}

        <NewsletterSignupPopUp />
      </div>
    </ParallaxProvider>
  </ThemeProvider>
);

export default Layout;
