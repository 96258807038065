import {
  IFontColor,
  IFontFamily,
  IFontLineHeight,
  IFontSize,
  IFontSpacing,
  IFontWeight,
  ISizeSpacing,
  ISizeThickness,
  ITextDecoration,
  ITheme,
  IThemeColour,
  IThemeRadius
} from "./theme-definition";

const styledTheme = require("styled-theming");

export interface IThemeParams<variant> {
  variant: variant;
  theme: ITheme;
}

export const borderRadiusHelper = (props: IThemeParams<keyof IThemeRadius>): string =>
  styledTheme.variants("mode", "variant", {
    bottomLeft: { normal: props.theme.radius.bottomLeft },
    bottomRight: { normal: props.theme.radius.bottomRight },
    radius: { normal: props.theme.radius.radius },
    topLeft: { normal: props.theme.radius.topLeft },
    topRight: { normal: props.theme.radius.topRight }
  })(props);

export const colourHelper = (props: IThemeParams<keyof IThemeColour>): string =>
  styledTheme.variants("mode", "variant", {
    bottleGreen: { normal: props.theme.color.bottleGreen },
    tangerine: { normal: props.theme.color.tangerine },
    soliel: { normal: props.theme.color.soliel },
    ultraviolet: { normal: props.theme.color.ultraviolet },
    warmGrey1: { normal: props.theme.color.warmGrey1 },
    black: { normal: props.theme.color.black },
    borderColor: { normal: props.theme.color.borderColor },
    borderColorInverse: { normal: props.theme.color.borderColorInverse },
    brandColor: { normal: props.theme.color.brandColor },
    brandColorLight: { normal: props.theme.color.brandColorLight },
    brandOneInverse: { normal: props.theme.color.brandOneInverse },
    brandOnePrimary: { normal: props.theme.color.brandOnePrimary },
    brandThreeInverse: { normal: props.theme.color.brandThreeInverse },
    brandThreePrimary: { normal: props.theme.color.brandThreePrimary },
    brandTwoInverse: { normal: props.theme.color.brandTwoInverse },
    brandTwoPrimary: { normal: props.theme.color.brandTwoPrimary },
    periwinkle: { normal: props.theme.color.periwinkle },
    lightGrey: { normal: props.theme.color.lightGrey },
    midGrey: { normal: props.theme.color.midGrey },
    darkGrey: { normal: props.theme.color.darkGrey },
    lightestGrey: { normal: props.theme.color.lightestGrey },
    altLightGrey: { normal: props.theme.color.altLightGrey },
    silverChalice: { normal: props.theme.color.silverChalice },
    lavenderRose: { normal: props.theme.color.lavenderRose },
    carnationPink: { normal: props.theme.color.carnationPink },
    errorColor: { normal: props.theme.color.errorColor },
    successColor: { normal: props.theme.color.successColor },
    warningColor: { normal: props.theme.color.warningColor },
    white: { normal: props.theme.color.white }
  })(props);

export const textColourHelper = (props: IThemeParams<keyof IFontColor>): string =>
  styledTheme.variants("mode", "variant", {
    primary: { normal: props.theme.color.fonts.primary },
    secondary: { normal: props.theme.color.fonts.secondary },
    third: { normal: props.theme.color.fonts.third || props.theme.color.fonts.primary },
    grey: { normal: props.theme.color.midGrey },
    lightGrey: { normal: props.theme.color.lightGrey },
    altLightGrey: { normal: props.theme.color.altLightGrey }
  })(props);

export const fontFamilyHelper = (props: IThemeParams<keyof IFontFamily>): string =>
  styledTheme.variants("mode", "variant", {
    bold: { normal: props.theme.font.family.bold },
    heavy: { normal: props.theme.font.family.heavy },
    light: { normal: props.theme.font.family.light },
    regular: { normal: props.theme.font.family.regular }
  })(props);

export const fontSizeHelper = (props: IThemeParams<keyof IFontSize>): string =>
  styledTheme.variants("mode", "variant", {
    display_9: { normal: props.theme.font.size.display_9 },
    display_8: { normal: props.theme.font.size.display_8 },
    display_7: { normal: props.theme.font.size.display_7 },
    display_6: { normal: props.theme.font.size.display_6 },
    display_5: { normal: props.theme.font.size.display_5 },
    display_4: { normal: props.theme.font.size.display_4 },
    display_3: { normal: props.theme.font.size.display_3 },
    display_2: { normal: props.theme.font.size.display_2 },
    display_1: { normal: props.theme.font.size.display_1 },
    title: { normal: props.theme.font.size.title },
    subhead_3: { normal: props.theme.font.size.subhead_3 },
    subhead_2: { normal: props.theme.font.size.subhead_2 },
    subhead_1: { normal: props.theme.font.size.subhead_1 },
    body_2: { normal: props.theme.font.size.body_2 },
    body_1: { normal: props.theme.font.size.body_1 },
    small_3: { normal: props.theme.font.size.small_3 },
    small_2: { normal: props.theme.font.size.small_2 },
    small_1: { normal: props.theme.font.size.small_1 }
  })(props);

export const fontLineHeightHelper = (
  props: IThemeParams<keyof IFontLineHeight>
): string =>
  styledTheme.variants("mode", "variant", {
    default: { normal: props.theme.font.lineHeight.default },
    normalized: { normal: props.theme.font.lineHeight.normalized },
    small: { normal: props.theme.font.lineHeight.small },
    large: { normal: props.theme.font.lineHeight.large }
  })(props);

export const fontWeightHelper = (props: IThemeParams<keyof IFontWeight>): string =>
  styledTheme.variants("mode", "variant", {
    bold: { normal: props.theme.font.weight.bold },
    heavy: { normal: props.theme.font.weight.heavy },
    light: { normal: props.theme.font.weight.light },
    regular: { normal: props.theme.font.weight.regular }
  })(props);

export const fontSpaceHelper = (props: IThemeParams<keyof IFontSpacing>): string =>
  styledTheme.variants("mode", "variant", {
    default: { normal: props.theme.font.spacing.default },
    large: { normal: props.theme.font.spacing.large },
    medium: { normal: props.theme.font.spacing.medium },
    small: { normal: props.theme.font.spacing.small }
  })(props);

export const spacingHelper = (props: IThemeParams<keyof ISizeSpacing>): string =>
  styledTheme.variants("mode", "variant", {
    l: { normal: props.theme.sizes.spacing.l },
    m: { normal: props.theme.sizes.spacing.m },
    ml: { normal: props.theme.sizes.spacing.ml },
    ms: { normal: props.theme.sizes.spacing.ms },
    s: { normal: props.theme.sizes.spacing.s },
    xl: { normal: props.theme.sizes.spacing.xl },
    xs: { normal: props.theme.sizes.spacing.xs },
    xxl: { normal: props.theme.sizes.spacing.xxl },
    xxs: { normal: props.theme.sizes.spacing.xxs }
  })(props);

export const thicknessHelper = (props: IThemeParams<keyof ISizeThickness>): string =>
  styledTheme.variants("mode", "variant", {
    l: { normal: props.theme.sizes.thickness.l },
    m: { normal: props.theme.sizes.thickness.m },
    ml: { normal: props.theme.sizes.thickness.ml },
    ms: { normal: props.theme.sizes.thickness.ms },
    s: { normal: props.theme.sizes.thickness.s },
    xl: { normal: props.theme.sizes.thickness.xl },
    xs: { normal: props.theme.sizes.thickness.xs },
    xxl: { normal: props.theme.sizes.thickness.xxl },
    xxs: { normal: props.theme.sizes.thickness.xxs }
  })(props);

export const textDecorationHelper = (
  props: IThemeParams<keyof ITextDecoration>
): string =>
  styledTheme.variants("mode", "variant", {
    underlineLight: { normal: props.theme.font.decoration.underlineLight },
    underlineNormal: { normal: props.theme.font.decoration.underlineNormal }
  })(props);
