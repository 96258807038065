import * as React from "react";
import Helmet from "react-helmet";

import {
  IFixed,
  ILocalFile
} from "src/types/local-files";

import SocialMediaDefaultImg from "../../assets/images/social-media-default-image.jpg";
import { DynamicImageSrcString } from "../../templates/utils/dynamicImage";
import forceTrailingSlash from "../../templates/utils/forceTrailingSlash";

interface IHelmetProps {
  children?: JSX.Element;
  defer?: boolean;
  title?: string;
  description?: string;
  updatedAt?: string;
  ogImage?: ILocalFile<IFixed>;
  ogType?: string;
  twitterAuthorUsername?: string;
  canonicalPath: string;
  robots?: string;
}

const HelmetWrapper = ({
  children,
  defer,
  title,
  description,
  updatedAt,
  ogImage,
  ogType,
  twitterAuthorUsername,
  robots,
  canonicalPath
}: IHelmetProps) => {
  const origin = "https://www.gravitywell.co.uk";

  const defaultTitle =
    "Gravitywell - Creative Technology for startups and innovators, Bristol, UK";

  const defaultDescription =
    "Gravitywell is an award winning digital design and web development agency delivering websites, apps, eCommerce, branding, and more. Bristol, UK";

  const twitterSiteUsername = "@gravitywelluk";
  const fbAppId = "383097742554727";
  const canonicalUrl = forceTrailingSlash(`${origin}${canonicalPath}`);
  const socialMediaDefaultImgUrl = `${origin}${SocialMediaDefaultImg}`;

  const socialMediaImgUrl: string | undefined = ogImage ?
    `${origin}${DynamicImageSrcString(ogImage)}` :
    undefined;

  const twitterCreatorUsername = twitterAuthorUsername ?
    `@${twitterAuthorUsername}` :
    twitterSiteUsername;

  return (
    <Helmet defer={defer || false}>
      <html
        lang="en"
        prefix="og: http://ogp.me/ns# schema: http://schema.org/"
      />

      <title>
        {title || defaultTitle}
      </title>

      <meta
        httpEquiv="Content-Type"
        content="text/html; charset=UTF-8"
      />

      <meta
        httpEquiv="X-UA-Compatible"
        content="IE=edge, chrome=1"
      />

      <meta
        name="format-detection"
        content="telephone=no"
      />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      <meta
        name="robots"
        content={robots || "index, follow"}
      />

      <meta
        name="title"
        content={title || defaultTitle}
      />

      <meta
        name="description"
        content={description || defaultDescription}
      />

      <meta
        property="og:site_name"
        content="Gravitywell"
      />

      <meta
        property="og:title"
        content={title}
      />

      <meta
        property="og:description"
        content={description || defaultDescription}
      />

      <meta
        property="og:type"
        content={ogType || "website"}
      />

      <meta
        property="og:url"
        content={canonicalUrl}
      />

      <meta
        property="og:image"
        content={socialMediaImgUrl || socialMediaDefaultImgUrl}
      />

      <meta
        property="og:image:type"
        content="image/jpeg"
      />

      <meta
        property="og:image:width"
        content="1200"
      />

      <meta
        property="og:image:height"
        content="600"
      />

      <meta
        property="og:locale"
        content="en_GB"
      />

      <meta
        property="og:updated_time"
        content={updatedAt}
      />

      <meta
        property="fb:app_id"
        content={fbAppId}
      />

      <meta
        name="twitter:card"
        content="summary_large_image"
      />

      <meta
        name="twitter:site"
        content={twitterSiteUsername}
      />

      <meta
        name="twitter:creator"
        content={twitterCreatorUsername}
      />

      <link
        rel="canonical"
        href={canonicalUrl}
      />

      {/* <!-- Start of Embed Code --> */}

      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/4750743.js"
      />

      {/* <!-- End of Embed Code --> */}

      {children}
    </Helmet>
  );
};

export default HelmetWrapper;
