import React from "react";

import styled from "../../theme/styled";
import {
  GravitywellIcons,
  IIcons
} from "./icon-list";

interface IProps {
  className?: string;
  icon?: keyof IIcons;
  custom?: {
    name: string;
    paths: Array<{
      d?: string;
      fill?: string;
      linearGradient?: {
        id: string;
        x1: string;
        y1: string;
        x2: string;
        y2: string;
        stops: Array<{ offset: string; color: string }>;
      };
    }>;
    viewBox: string[];
  };
  size?: number;
  height?: number;
  width?: number;
  color?: string;
  children?: React.ReactChild;
}

const Icon = (props: IProps) => {
  const iconProps = Object.assign({}, props);

  const styles = {
    className: props.className,
    svg: {
      display: "inline-block",
      verticalAlign: "middle",
      overflow: "visible"
    },
    path: { fill: props.color }
  };

  const iconData: string = props.icon ? GravitywellIcons[ props.icon ] : "";

  // Convert rems to px
  if (props.size) {
    iconProps.size = props.size * 10;
  }

  if (props.custom) {
    return (
      <IconStyled className={styles.className}>
        <svg
          width="100%"
          height="100%"
          style={styles.svg}
          viewBox={props.custom.viewBox.join(" ")}
        >
          {props.custom.paths.map((p, i) => {
            let gradient = null;
            let fill = props.color ? props.color : p.fill || "#000000";

            if (p.linearGradient) {
              fill = `url(#${p.linearGradient.id})`;

              gradient = (
                <linearGradient
                  gradientUnits="userSpaceOnUse"
                  x1={p.linearGradient.x1}
                  x2={p.linearGradient.x2}
                  y1={p.linearGradient.y1}
                  y2={p.linearGradient.y2}
                  id={p.linearGradient.id}
                >
                  {p.linearGradient.stops.map((stop, j) => (
                    <stop
                      key={j}
                      offset={stop.offset}
                      style={{ stopColor: stop.color }}
                    />
                  ))}
                </linearGradient>
              );
            }

            return (
              <React.Fragment key={i}>
                {gradient}

                <path
                  style={{}}
                  key={i}
                  fill={fill}
                  d={p.d}
                />
              </React.Fragment>
            );
          })}
        </svg>
      </IconStyled>
    );
  }

  return (
    <IconStyled
      className={styles.className}
      style={{ width: `${props.size}rem` }}
    >
      <svg
        width="100%"
        height="100%"
        style={styles.svg}
        viewBox="0 0 1024 1024"
      >
        <path
          style={styles.path}
          d={iconData}
        />
      </svg>
    </IconStyled>
  );
};

Icon.defaultProps = { size: 2 };

export default Icon;

const IconStyled = styled.i`
  display: inline-block;

  svg {
    display: block;
  }
`;