import React from "react";

import styled, { injectGlobal } from "../../theme/styled";
import { colourHelper } from "../../theme/theme-helper";
import {
  ColumnWidthWrapper,
  FullWidthColumnWrapper
} from "../confines-wrappers";
import Icon from "../icon";
import Link from "../link";
import Logo from "../logo";
import Menu from "../menu";

interface IState {
  logo: boolean;
  theme: "light" | "dark";
  backgroundTheme: "light" | "dark";
  mobileMenuOpen: boolean;
}

interface IProps {
  isHome?: boolean;
  initialLogo?: boolean;
  initialTheme?: "light" | "dark";
}

const initialState: IState = {
  logo: false,
  theme: "dark",
  backgroundTheme: "dark",
  mobileMenuOpen: false
};

injectGlobal`
  :root {
    --nav-height: 6.2rem;
    @media screen and (min-width: 768px) {
      --nav-height: 8rem;
    }
  }
`;

const HeaderWrapper = styled.div`
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  border-bottom: 0.2rem solid rgba(170, 170, 170, 0.2);

  --headerBg: ${({ theme }) => colourHelper({
    theme,
    variant: "soliel"
  })}

  --logoWidth: max(min(50%, 24rem), 16rem);
  @media (min-width: 1020px) {
    --logoWidth: 22rem;
  }
  @media (min-width: 1094px) {
    --logoWidth: 24rem;
  }
  .navigation-wrapper {
    .logo-container {
      width: var(--logoWidth);
    }
  }
  
  .navigation-wrapper {
    transition: 0.4s ease-in-out;
    position: relative;
    height: var(--nav-height);
    display: flex;
    align-items: center;

    .mobile-hamburger-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2rem;
      z-index: 2;

      &:hover {
        cursor: pointer;
      }

      .mobile-hamburger {
        fill: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};

        &.dark {
          fill: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
        }
      }
    }

    .logo-container {
      position: relative;
      transition: none;
      display: block;
      margin: 0 auto;
      text-align: center;

      .logo {
        &.no-link {
          position: absolute;
          inset: 0;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .navigation {
      display: none;
    }

    .mobile-navigation-container {
      transition: 0.4s ease-in-out;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 99999;
      height: 100vh;
      width: 28rem;

      &.closed {
        left: -28rem;
      }

      .navigation {
        display: block;
      }
    }

    .contact {
      display: none;
      transition: 0.15s ease-in-out;
    }
  }

  &.dark {
    .navigation-wrapper {
      .contact {
        color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
        border-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};

        &:hover {
          color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "black"
    })} !important;
          background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};
          border-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};
        }
      }
    }

    &.filled {
      .navigation-wrapper {
        .contact {
          &:hover {
            color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })} !important;
            background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "black"
    })} !important;
          }
        }
      }
    }
  }

  &.light {
    .navigation-wrapper {
      .contact {
        &:hover {
          color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
          background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "black"
    })};
          border-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "black"
    })};
        }
      }
    }
  }

  @media (min-width: 600px) {
    .navigation-wrapper {
      .contact {
        display: block;
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        z-index: 2;
        padding: 0.6rem 1.8rem;
        font-size: 1.6rem;
        text-decoration: none;
        color: black;
        border: 0.2rem solid black;

        &.light {
          color: white;
          border: 0.2rem solid white;
        }
      }
    }
  }

  @media (min-width: 1020px) {
    overflow: hidden;

    .navigation-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .mobile-hamburger-container {
        display: none;
      }

      .logo-container {
        position: absolute;
        top: 50%;
        left: 2rem;
        transform: translateY(-50%);
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;

        &.no-transform {
          transform: none;
        }

        .logo {
          &.no-link {
            top: 0;
          }
        }
      }

      .navigation {
        display: inline-block;
      }

      .contact {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
      }

      .mobile-navigation-container {
        display: none;
      }
    }
  }
`;

class Header extends React.Component<IProps> {
  public readonly state: IState = initialState;

  public componentDidMount() {
    if (this.props.initialLogo === true) {
      this.setState({ logo: true });
    }

    if (this.props.initialTheme) {
      this.setState({ theme: this.props.initialTheme });
    }

    window.addEventListener("scroll", () => this.handleScroll());
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  public render() {
    return (
      <HeaderWrapper
        id="navigation-container"
        className={`navigation-container ${this.props.initialTheme}`}
        role="banner"
      >
        <ColumnWidthWrapper className="navigation-wrapper">
          <div
            id="mobile-hamburger-container"
            className="mobile-hamburger-container"
            onClick={() => this.toggleMobileMenu()}
          >
            <Icon
              className={`mobile-hamburger ${this.state.theme}`}
              icon="menu"
              size={2.5}
            />
          </div>

          <div
            id="logo"
            className="logo-container"
          >
            <Logo type={this.state.theme} />

            <Logo
              type="light"
              noLink
            />
          </div>

          <div className="navigation">
            <Menu menuType={this.state.theme} />
          </div>

          <Link
            type="none"
            className="contact"
            href="/contact"
          >
            Contact
          </Link>

          <div
            className={`mobile-navigation-container ${
              this.state.mobileMenuOpen ? "open" : "closed"
            }`}
          >
            <Menu
              menuType="mobile"
              onToggle={() => this.toggleMobileMenu()}
            />
          </div>
        </ColumnWidthWrapper>
      </HeaderWrapper>
    );
  }

  private toggleMobileMenu() {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  }

  private handleScroll() {
    const navDom = document.getElementById("navigation-container");
    const logo = document.getElementById("logo");
    const mobHam = document.getElementById("mobile-hamburger-container");
    const classNameDarkRegex = /dark/gi;
    const classNameFilledRegex = /filled/gi;
    let navDomVpData: ClientRect | DOMRect;
    let navDomClasses: DOMTokenList;
    let scrollOpacity = 0;
    let navDomATags: any = [];
    let aTagHexColor = 0;

    // If the following DOMs exists
    if (navDom && logo && mobHam) {
      navDomVpData = navDom.getBoundingClientRect();
      scrollOpacity = window.scrollY / navDomVpData.height;
      navDomATags = navDom.getElementsByTagName("a");
      navDomClasses = navDom.classList;

      // Make sure that the scrollOpacity does not go below 0
      if (scrollOpacity < 0) {
        scrollOpacity = 0;
      }

      // Make sure that the scrollOpacity does not go above 1
      if (scrollOpacity > 1) {
        scrollOpacity = 1;
      }

      // Only update styles if the scrollOpacity is between 0 and 1
      if (scrollOpacity >= 0 && scrollOpacity <= 1) {
        // If the header has less the 1 opacity and has the class 'filled',
        // remove it
        if (scrollOpacity < 1 && classNameFilledRegex.test(navDom.className)) {
          navDomClasses.remove("filled"); // navDom.className += ' filled';
        }

        // If the header equals 1 opacity and does not have the class 'filled',
        // add it
        if (
          scrollOpacity === 1 &&
          !classNameFilledRegex.test(navDom.className)
        ) {
          navDomClasses.add("filled");
        }

        // Ensure the alpha is between 0 and 1
        const validAlpha = Math.min(1, Math.max(0, scrollOpacity));
        // Convert the decimal alpha to hex
        const alphaHex = Math.round(validAlpha * 255).toString(16).padStart(2, "0");

        navDom.style.backgroundColor = getComputedStyle(navDom).getPropertyValue("--headerBg") + alphaHex;

        navDom.style.boxShadow = `0 0 1px ${scrollOpacity *
          4}px rgba(0,0,0,${scrollOpacity * 0.1})`;

        // If the theme is 'dark', set the styles accordingly
        if (classNameDarkRegex.test(navDom.className)) {
          aTagHexColor = 255 - scrollOpacity * 255;

          if (window.innerWidth >= 600) {
            // Set the color for each <A> tag
            for (const i in navDomATags) {
              if (navDomATags[ i ] && Number.isInteger(parseInt(i, 0))) {
                const el = navDomATags[ i ];

                // Set the color and borderColor
                el.style.color = `rgba(${aTagHexColor}, ${aTagHexColor}, ${aTagHexColor}, 1)`;
                el.style.borderColor = `rgba(${aTagHexColor}, ${aTagHexColor}, ${aTagHexColor}, 1)`;
              }
            }
          }

          // Set the navigation bottom border
          navDom.style.borderBottomColor = `rgba(255,255,255,${0.2 -
            scrollOpacity * 0.2})`;

          // If the mobile hamburger has an SVG child
          if (mobHam.getElementsByTagName("svg")[ 0 ]) {
            const mobHamR = 255 - (scrollOpacity * 100 * (255 - 241)) / 100;
            const mobHamG = 255 - (scrollOpacity * 100 * (255 - 95)) / 100;
            const mobHamB = 255 - (scrollOpacity * 100 * (255 - 34)) / 100;

            mobHam.getElementsByTagName(
              "svg"
            )[ 0 ].style.fill = `rgb(${mobHamR}, ${mobHamG}, ${mobHamB})`;
          }

          // If the window is greater than 768px and the logo has a 2nd svg DOM
          if (logo.getElementsByTagName("i")[ 1 ]) {
            logo.getElementsByTagName(
              "i"
            )[ 1 ].style.opacity = `${scrollOpacity}`;
          }
        } else {
          aTagHexColor = scrollOpacity * 255;

          navDom.style.borderBottomColor = `rgba(170,170,170,${0.2 -
            scrollOpacity * 0.2})`;
        }
      }
    }
  }
}

export default Header;
